import React from 'react'

const CustomSoftwareService = (props) => (
  <>
    <div className="custom-software-service">
      <h2 className="title">Our Products</h2>
      <div className="custom-software">
        <img className="software-image" src={props.gatewayImages[0]} alt="Data Composer" />
        <a href="https://www.datacomposer.app/">
          <h5>Data Composer</h5>
        </a>
      </div>
      <div className="custom-software">
        <img className="software-image" src={props.gatewayImages[1]} alt="Kamino" />
        <a href="https://chrome.google.com/webstore/detail/kamino/ffdebockfdjileaojbbccofhgncmioaf">
          <h5>Kamino</h5>
        </a>
      </div>
    </div>
    <div className="custom-software-service">
      <h2 className="title">Client Products</h2>
      <div className="custom-software">
        <img className="software-image" src={props.clientImages[0]} alt="RICOH Tours" />
        <a href="https://www.ricoh360.com/tours/">
          <h5>RICOH Tours</h5>
        </a>
      </div>
    </div>
  </>
)

export default CustomSoftwareService
