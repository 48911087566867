import React from 'react'

const StaffAugmentation = (props) => (
  <>
    <div className="staff-augmentation-service">
      <h2 className="title">Client Websites</h2>
      <div className="staff-augmentation">
        <img className="staff-augmentation-image" src={props.staffAugmentationImages ? props.staffAugmentationImages[1] : ''} alt="VHT" />
        <a href="https://vhtcx.com">
          <h5>VHT</h5>
        </a>
      </div>
      <div className="staff-augmentation">
        <img className="staff-augmentation-image" src={props.staffAugmentationImages ? props.staffAugmentationImages[2] : ''} alt="Ricoh" />
        <a href="https://www.ricoh.com">
          <h5>Ricoh</h5>
        </a>
      </div>
      <div className="staff-augmentation">
        <img className="staff-augmentation-image" src={props.staffAugmentationImages ? props.staffAugmentationImages[3] : ''} alt="Nucor-Yamato" />
        <a href="http://www.nucoryamato.com">
          <h5>Nucor-Yamato</h5>
        </a>
      </div>
    </div>
  </>
)

export default StaffAugmentation
