import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'
import CustomSoftwareService from '../components/CustomSoftwareService'
import WebsiteService from '../components/WebsiteService'
import StaffAugmentation from '../components/StaffAugmentation'

const Service = ({ data }) => {
  const { title, gatewayImages, clientImages, websiteImages } = data.markdownRemark.frontmatter
  const { html } = data.markdownRemark
  return (
    <Layout bodyClass="page-service">
      <div className="strip strip-white strip-diagonal">
        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-8">
              <div className="service service-single">
                <h1 className="title">{title}</h1>
                <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
                {title === 'Custom Software' && <CustomSoftwareService gatewayImages={gatewayImages} clientImages={clientImages} />}
                {title === 'Websites' && <WebsiteService websiteImages={websiteImages} />}
                {title === 'Staff Augmentation' && <StaffAugmentation staffAugmentationImages={websiteImages} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        websiteImages
        gatewayImages
        clientImages
      }
      html
    }
  }
`

export default Service
