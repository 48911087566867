import React from 'react'

const WebsiteService = (props) => (
  <>
    <div className="website-service">
      <h2 className="title">Client Websites</h2>
      <div className="website">
        <img className="website-image" src={props.websiteImages ? props.websiteImages[0] : ''} alt="Ricoh Tours" />
        <a href="https://www.ricoh360.com/tours/">
          <h5>RICOH Tours</h5>
        </a>
      </div>
      <div className="website">
        <img className="website-image" src={props.websiteImages ? props.websiteImages[1] : ''} alt="Haag Brown" />
        <a href="https://www.haagbrown.com/">
          <h5>Haag Brown</h5>
        </a>
      </div>
      <div className="website">
        <img className="website-image" src={props.websiteImages ? props.websiteImages[2] : ''} alt="Garner Family Pharmacy" />
        <a href="https://garnerfamilyrx.com/">
          <h5>Garner Family Pharmacy</h5>
        </a>
      </div>
    </div>
  </>
)

export default WebsiteService
